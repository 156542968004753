/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.container{
	width:  100%;
	margin:0;
	display: block;
}

.text-head{padding: 80px 0}
.text-head h1{font-size:35px;text-align: center;}

